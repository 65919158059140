<template>
  <label class="search-container">
    <input class="search-box" type="text"
           @keydown.enter="search(true)"
           v-model="query"
           :placeholder="translations[getSearchPlaceholder()]"/>
  </label>
</template>

<script>
export default {
  name: 'HeaderSearch',
  data() {
    return {
      query: '',
      searchGuide: 'guide',
      searchArticle: 'article',
    };
  },
  computed: {
    lang() {
      return this.$store.getters.getLang;
    },
    translations() {
      return this.$store.getters.getTranslations;
    },
    searchFor() {
      return this.$store.getters.getSearchFor;
    },
    currentPage() {
      return this.$store.getters.getCurrentPage;
    },
    stateQuery() {
      return this.$store.getters.getSearchQuery;
    },
  },
  watch: {
    currentPage() {
      this.search();
    },
  },
  methods: {
    getSearchPlaceholder() {
      if (this.searchFor === this.searchGuide) {
        return 'search_guide';
      } if (this.searchFor === this.searchArticle) {
        return 'search_article';
      }
      return false;
    },
    setQueryToState() {
      if (this.query !== '') {
        this.$store.commit('setSearchQuery', this.query);
      }
    },
    search(queryChanged) {
      this.setQueryToState();
      if (queryChanged) {
        this.$store.commit('setCurrentPage', 1);
      }
      const searchParams = {
        lang: this.lang,
        query: this.stateQuery,
        page: this.currentPage,
      };

      if (this.searchFor === this.searchGuide) {
        this.$store.dispatch('searchGuide', searchParams);
        this.$router.push({ name: 'GuideSearchResults' });
      } else if (this.searchFor === this.searchArticle) {
        this.$store.dispatch('searchArticle', searchParams);
        this.$router.push({ name: 'ArticleSearchResults' });
      }
    },
  },
};
</script>

<style scoped lang="scss">
  .search-container {
    display: block;
    position: relative;
    max-width: 200px;
    width: 100%;
    margin-right: 20px;
    &:after {
      content: "";
      display: block;
      width: 15px;
      height: 15px;
      background: url("../../assets/images/search-icon.svg") no-repeat center / contain;
      position: absolute;
      right: 8px;
      top: 8px;
    }
  }
  .search-box {
    height: 30px;
    border: 1px solid #C8C8C8;
    border-radius: 5px;
    padding: 0 25px 0 10px;
    outline: none;
    max-width: 100%;
    width: 100%;
  }
</style>

<template>
  <header id="header" class="header-wrapper">
    <div class="container">
      <div class="header-top-content">
        <div>
          <router-link :to="{name:'WelcomePage'}">
            <img src="~@/assets/images/logo.svg" class="logo"/>
          </router-link>
          <h6 class="header-title">
            <span>{{translations['header_title_pt_1']}}</span> <br/>
            {{translations['header_title_pt_2']}}
          </h6>
        </div>
        <div>
          <searchBox></searchBox>
          <img src="~@/assets/images/armenian-coa.svg" class="logo"/>
        </div>
      </div>
    </div>

    <nav class="navbar-container">
      <div class="container">
        <div class="mobile-search">
          <searchBox></searchBox>
        </div>
        <div class="hamburger" :class="{'is_active': sidebarIsOpen}" @click="toggleSidebar">
          <div class="hamburger-box">
            <div class="hamburger-inner inner-1"></div>
            <div class="hamburger-inner inner-2"></div>
            <div class="hamburger-inner inner-3"></div>
          </div>
        </div>

        <div class="nav-wrapper" :class="{'is_active': sidebarIsOpen}">
          <div class="nav-inner">
            <ul class="nav">
              <li class="nav-item dropdown"
                  v-for="guideCollection in guideList" :key="guideCollection.id">
                <div class="dropdown-toggle nav-link"
                     :class="{'router-link-active': isActiveRoute($route.params.guideId, guideCollection)}"
                     @click="toggleGuidesDropdown(guideCollection.id)">
                  {{guideCollection.title}}
                </div>
                <div class="dropdown-container" v-show="openGuideDropdown === guideCollection.id">
                  <div class="dropdown-menu">
                    <template v-for="guide in guideCollection.guides">
                      <router-link :key="guide.id"
                                   v-if="guide.title && !(lang === 'en' && guide.id === 26)"
                                   @click.native="setSearchFor(searchGuide)"
                                   :class="{'sub-menu': guide.id === 18 || guide.id === 20}"
                                   :to="{name:'Guide', params: {guideId: guide.id}}">
                        {{guide.title}}
                      </router-link>
                    </template>
                  </div>
                  <div class="overlay" @click="toggleGuidesDropdown"></div>
                </div>
              </li>
              <li class="nav-item">
                <router-link class="nav-link"
                             :class="{'router-link-active': $route.name === 'TaxInfoArticle'}"
                             @click.native="setSearchFor(searchArticle)"
                             :to="{name:'TaxInfo'}">
                  {{translations['tax_code_title']}}
                </router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link"
                             :to="{name:'Feedback'}">
                  {{translations['feedback']}}
                </router-link>
              </li>
            </ul>
            <div class="nav lang-change">
              <div @click="changeLang(hy)"
                   class="lang nav-item">
                <div class="nav-link hy" :class="{'is_active': lang === hy}">ՀԱՅ</div>
              </div>
              <div @click="changeLang(en)"
                   class="lang nav-item">
                <div class="nav-link en" :class="{'is_active': lang === en}">ENG</div>
              </div>
            </div>
          </div>
          <div class="overlay" @click="toggleSidebar"></div>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
import searchBox from './HeaderSearch.vue';

export default {
  name: 'Header',
  components: {
    searchBox,
  },
  created() {
    this.getGuideList();
  },
  data() {
    return {
      sidebarIsOpen: false,
      hy: 'hy',
      en: 'en',
      searchGuide: 'guide',
      searchArticle: 'article',
      openGuideDropdown: null,
    };
  },
  computed: {
    lang() {
      return this.$store.getters.getLang;
    },
    guideList() {
      return this.$store.getters.getGuideList;
    },
    guideId() {
      return this.$route.params.guideId;
    },
    translations() {
      return this.$store.getters.getTranslations;
    },
  },
  watch: {
    lang() {
      this.getGuideList();
    },
    $route(to, from) {
      if (to.name === 'Guide' && to.params.guideId !== from.params.guideId) {
        this.getActiveGuide();
      }
    },
  },
  methods: {
    getGuideList() {
      this.$store.dispatch('getGuideList', this.lang).then(() => {
        this.getActiveGuide();
      });
    },
    changeLang(lang) {
      this.$store.commit('setLang', lang);
    },
    toggleSidebar() {
      this.sidebarIsOpen = !this.sidebarIsOpen;
    },
    toggleGuidesDropdown(id) {
      this.openGuideDropdown = id || null;
      this.toggleSidebar();
    },
    setSearchFor(searchFor) {
      this.$store.commit('setSearchFor', searchFor);
      if (searchFor === this.searchGuide) {
        this.toggleGuidesDropdown();
      } else {
        this.toggleSidebar();
      }
    },
    isActiveRoute(activeRouteId, guideCollection) {
      let containsActiveGuide;
      guideCollection.guides.forEach((guide) => {
        if (+activeRouteId === guide.id) {
          containsActiveGuide = true;
        }
      });

      return containsActiveGuide;
    },
    getActiveGuide() {
      if (this.$route.params.guideId) {
        this.$store.dispatch('getActiveGuide', { id: this.guideId, lang: this.lang });
      }
    },
  },
};
</script>
<style scoped lang="scss">
  @import "@/assets/styles/layout/Header.scss";
</style>
